.iframe-custom-height {
    height: calc(100% - 4rem);
  }
  
  /* Kleinere Bildschirme */
  @media (max-width: 600px) {
    .iframe-custom-height {
      height: calc(100% - 5rem);  /* Mehr Platz abziehen auf kleinen Geräten */
    }
  }
  
  /* Noch kleinere Bildschirme */
  @media (max-width: 400px) {
    .iframe-custom-height {
      height: calc(100% - 8rem);  /* Noch mehr Platz abziehen auf sehr kleinen Geräten */
    }
  }

    /* Noch kleinere Bildschirme */
    @media (max-width: 300px) {
      .iframe-custom-height {
        height: calc(100% - 10rem);  /* Noch mehr Platz abziehen auf sehr kleinen Geräten */
      }
    }