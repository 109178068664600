.markdown h1{
    margin: 1.3rem 0 .9rem;
    font-weight: 700;
    font-size: 1.5rem;
}

.markdown h2{
    margin: 1.3rem 0 .9rem;
    font-weight: 600;
    font-size: 1.25rem;
}

.markdown p{
    margin: 0 0 .4rem;
}

.markdown li{
    list-style-type: disc;
}